.page-me-content {
  margin: auto;
  width: 50%;
  min-width: 800px;

  > .password-form {
    margin-top: 32px;
  }
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);