.document-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 80%;
  margin: auto;
}

@media (max-width: 1156px) {
  .document-edit {
    width: 100%;
    margin: 0;
  }
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);