@import './theme/index.less';
@import '~antd/dist/antd.less';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ====
// from Refine base theme

// variables overrides
@layout-body-background: @background-color;

// sider
@layout-sider-background: rgba(0, 0, 0, 1);
@menu-bg: transparent;
@menu-inline-submenu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

// checkboxes
@checkbox-border-radius: 4px;

// classes overrides
.ant-btn-primary {
    background: @primary-gradient;
}
.ant-btn-link {
  padding: 0;
}
.ant-form-item {
  margin-bottom: 16px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.ant-page-header {
    border-radius: @border-radius-base;
}

// Disabled style for buttons (default text color is same as background)
.ant-btn[disabled], .ant-btn[disabled]:hover {
    color: @text-color;
}

.AteClickable {
    cursor: pointer;
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);