@import '../theme/index.less';

.login-page-container {
  height: 100vh;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.login-page-content {
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  white-space: pre-line;

  > * , .login-title {
    color: white;
  }
}

.login-form {
  height: 100%;
  min-width: 400px;
  align-self: center;

  > .ant-card-body {
    height: 100%;
  }
}

.login-form-tabs {
  height: 100%;
  border-bottom: 1px solid #f0f0f0;

  > .ant-tabs-content-holder {
  overflow-y: scroll;
  }
}

.form-actions {
  display: flex;
  gap: 16px
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);