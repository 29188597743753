.document-form, .language-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px
}

.language-item {
  display: grid;
  grid-template-areas:
    "lang docx pdf upload";
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border-radius: 4px;

  &:hover {
    background-color: #f8f8f8 ;
  }

  > .lang {
    grid-area: lang;
    justify-self: start;
  }

  > .docx {
    grid-area: docx;
  }

  > .pdf {
    &.ant-btn {
      width: fit-content;
    }
    grid-area: pdf;
  }

  > .upload {
    grid-area: upload;
  }
}

@media (max-width: 1156px) {
  .language-item {
    grid-template-areas:
      "lang docx pdf"
      "lang upload upload";
    grid-template-columns: 20% 40% 40%;
    gap: 0;
    row-gap: 4px;
  }


  .pdf.-replace {
    grid-area: docx;
  }
  .upload.-replace {
    grid-area: pdf;
  }
}

@media (max-width: 890px) {

  .upload span.label {
    display: none;
  }
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);