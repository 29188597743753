@import './theme/index.less';

.NxLayoutSider {
  height: 100vh;
  position: sticky !important;
  top: 0;
  overflow-y: scroll;
}

.NxLayoutSider > .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.NxMenu {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.ant-menu-item-group-title {
  color: white !important;
}

.NxMenuItem {
  padding-left: 16px !important;
}

.NxMenuItem.-active {
  background: @primary-gradient;
}

.NxMenuItem.NxMenuItem.-logoutButton {
  margin-top: auto;
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);