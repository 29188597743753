.init-page-container {
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.init-page-content {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  white-space: pre-line;

  > * , .title {
    color: white;
  }

  > .form {
    background-color: #fefefe;
    width: 600px;
    padding: 24px;
    border-radius: 2px;
  }
}

@primary-color: rgba(17,190,226,1);@error-color: red;@text-color: black;@info-color: yellow;@background-color: rgba(249, 248, 249, 1);@disabled-color: lightgrey;@primary-gradient: linear-gradient(90deg, rgba(66,66,255,1), @primary-color 50%);